

















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'GmoForm'
})
export default class GmoForm extends Vue {
  @Prop() private config!: { [key: string]: object }
  @Prop() private isUpdate!: boolean

  public submit() {
    (this.$refs.gmoForm as HTMLFormElement).submit()
  }
}
