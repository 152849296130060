







































import { Component, Vue } from 'vue-property-decorator'
import { Shift } from '@/services/Shift'
import routeNames from '@/constants/routeNames'
import { Getter } from 'vuex-class'
import { InvoiceSettlementStatusResponseModel, InvoiceSettlementStatusResponseModelStatusEnum, PolicyModel } from 'shift-policy-service-api-client'
import {
  ACTION_PRODUCT_EMPTY_CART, ACTION_RESET_PRODUCT_SERIALIZED_CONFIG, ACTION_SET_BUNDLE, ACTION_SET_CONVERT_QUOTE, ACTION_SET_PRODUCT_LOADED, ACTION_SET_SALE_JOURNEY_GMO_OK, ACTION_SET_SALE_JOURNEY_STATE_PAYMENT_COMPLETED
} from '@/store/modules/product/actionTypes'
import AonButton from '@/components/AonButton.vue'
import ImportantInfo from '@/components/ImportantInfo.vue'
import { Config } from '@/config'
import { Cart, EventBus } from '@uncharted/coverhub-framework'
import BackDashboardButton from '@/components/BackDashboardButton.vue'
import GmoForm from '@/views/Application/components/GmoForm.vue'
import { POLLING_NAMES } from '@/constants/polling'
import { saleJourneyActionHandler, SaleJourneyActionType } from '@/services/SaleJourneyService'
import constants from '@/constants'

@Component({
  name: 'Payment',
  components: { GmoForm, ImportantInfo, AonButton, BackDashboardButton }
})
export default class Payment extends Vue {
  @Getter('policy/convertedPolicy')
  private convertedPolicy!: PolicyModel

  @Getter('product/cart')
  private cart!: Cart | null

  private currentStatus = 'payment.info.starting'
  // this is only available when the payment is failed within the listed codes, then will show this code.
  private currentStatusCode = ''

  private invoiceId = ''

  private startedAttempt = false

  private retry: (() => any) | null = null

  private windowWasClosed = false

  private gmoConfig: { [key: string]: object } | null = null

  private useRedirect = Config.gmo.useRedirect

  private useDummy = sessionStorage.getItem('should-use-dummy')

  get type(): 'PAYMENT'|'DETAILS_UPDATE' {
    return this.$route.query.detailsUpdate?.toString() === 'true' ? 'DETAILS_UPDATE' : 'PAYMENT'
  }

  private handleMessage(event: MessageEvent) {
    if (event.origin !== window.location.origin || event.data.invoiceId !== this.invoiceId) return
    window.removeEventListener('message', this.handleMessage)
    const result = event.data.result
    this.windowWasClosed = true
    this.handleResult(result)
  }

  public async mounted() {
    // clear any previous listeners
    window.removeEventListener('message', this.handleMessage)

    this.goToDashboardIfCartEmpty()

    let settlement: InvoiceSettlementStatusResponseModel | null = null
    this.invoiceId = this.$route.query.invoiceId as string

    if (this.invoiceId) {
      settlement = await Shift.getSettlementStatus(this.invoiceId)
    }

    const existingResult = this.$route.query.result as string
    const possibleErrorCode = this.$route.query.code as string

    if (this.type === 'PAYMENT' && this.invoiceId && this.$store.state.product.saleJourneyState.isGmoOk) {
      if (settlement?.status === InvoiceSettlementStatusResponseModelStatusEnum.NotStarted) {
        this.currentStatus = 'payment.info.initiatePayment'
        this.attemptInvoiceSettlement()
      } else if (settlement?.status === InvoiceSettlementStatusResponseModelStatusEnum.Settled) {
        this.cleanUpStateAfterSettlement(() => {
          this.$router.replace({ name: constants.routeNames.DASHBOARD })
        })
      } else {
        setTimeout(() => {
          this.checkPayment(60000)
        }, 500)
      }
    } else if (existingResult) {
      // handle browser refresh
      if (saleJourneyActionHandler(this.$store.state.product.saleJourneyState) === SaleJourneyActionType.COMPLETED) {
        this.cleanUpStateAfterSettlement(() => {
          this.$router.replace({ name: constants.routeNames.DASHBOARD })
        })
      } else {
        this.startedAttempt = true
        this.handleResult(existingResult, possibleErrorCode)
      }
    } else if (this.useDummy) {
      this.currentStatus = 'payment.info.initiatePayment'
      this.attemptInvoiceSettlement('DUMMY')
    } else if (this.type === 'PAYMENT' && this.invoiceId && saleJourneyActionHandler(this.$store.state.product.saleJourneyState) === SaleJourneyActionType.NORMAL) {
      const invoice = await Shift.getSettlementStatus(this.invoiceId)
      if (invoice?.status === InvoiceSettlementStatusResponseModelStatusEnum.Settled) {
        this.cleanUpStateAfterSettlement(() => {
          this.$router.replace({ name: constants.routeNames.DASHBOARD })
        })
      } else {
        setTimeout(() => {
          this.checkPayment(60000)
        }, 500)
      }
    } else {
      this.getGmoFormConfig()
    }
  }

  public destroyed() {
    window.removeEventListener('message', this.handleMessage)
  }

  private goToDashboardIfCartEmpty() {
    if (this.type === 'PAYMENT' && (this.cart == null || this.cart.isEmpty)) {
      this.$router.push({
        name: routeNames.DASHBOARD
      })
    }
  }

  private getGmoFormConfig() {
    this.gmoConfig = null

    let redirectCompletionCallbackUrl = Config.gmo.redirectCompletionCallbackUrl + `?invoiceId=${this.invoiceId}`
    if (this.useRedirect) {
      redirectCompletionCallbackUrl = window.location.href
      // remove existing payment result and error code
      redirectCompletionCallbackUrl = redirectCompletionCallbackUrl.replace(/&result=[^&]*/, '')
      redirectCompletionCallbackUrl = redirectCompletionCallbackUrl.replace(/&code=[^&]*/, '')
    }

    Shift.getGmoFrontendPaymentConfig(this.invoiceId, redirectCompletionCallbackUrl)
      .then((gmoConfig) => {
        this.gmoConfig = gmoConfig
      })
  }

  private startSavedCardDetailsAttempt() {
    this.retry = null
    this.startedAttempt = true
    this.currentStatus = 'payment.info.starting'

    if (!this.useRedirect) {
      const redirectOnlyAgents = navigator.userAgent.toLocaleLowerCase().match(/yjapp|gsa/)
      let paymentWindow: Window | null = null
      if (!redirectOnlyAgents) {
        paymentWindow = window.open(window.location.origin + Config.publicPath + 'gmo-popup/index.html', '_blank')
      }
      if (!redirectOnlyAgents && paymentWindow) {
        const obtainedWindow: Window = paymentWindow
        this.windowWasClosed = false
        const config = this.gmoConfig
        if (config === null) {
          throw Error('GMO Config not provided.')
        }

        obtainedWindow.addEventListener('load', () => {
          const form = obtainedWindow.document.getElementById('gmoForm') as HTMLFormElement | null
          if (form === null) {
            console.info('Could not get GMO Form on popup window.')
            throw Error('Could not get GMO Form on popup window.')
          }
          form.action = config.ActionURL.toString()
          this.getInputElement(obtainedWindow.document, 'SiteID').value = config.SiteID.toString()
          this.getInputElement(obtainedWindow.document, 'MemberID').value = config.MemberID.toString()
          this.getInputElement(obtainedWindow.document, 'MemberName').value = config.MemberName.toString()
          this.getInputElement(obtainedWindow.document, 'ShopID').value = config.ShopID.toString()
          this.getInputElement(obtainedWindow.document, 'MemberPassString').value = config.MemberPassString.toString()
          this.getInputElement(obtainedWindow.document, 'RetURL').value = config.RetURL.toString()
          this.getInputElement(obtainedWindow.document, 'CancelURL').value = config.CancelURL.toString()
          this.getInputElement(obtainedWindow.document, 'DateTime').value = config.DateTime.toString()
          this.getInputElement(obtainedWindow.document, 'TemplateNo').value = this.type === 'DETAILS_UPDATE' ? '2' : '1'
          form.submit()
        }, true)

        this.currentStatus = 'payment.info.windowOpen'
        const timer = setInterval(() => {
          if (!obtainedWindow.closed) return
          if (!this.windowWasClosed) {
            window.removeEventListener('message', this.handleMessage)
            this.windowWasClosed = true
            this.getGmoFormConfig()
            console.info('Payment window closed early.')
            this.currentStatus = 'payment.error.windowClosedEarly'
            this.retry = this.startSavedCardDetailsAttempt
          }
          clearInterval(timer)
        }, 100)
        window.addEventListener('message', this.handleMessage)
      } else {
        try {
          console.info('Payment window open failed. Trying redirect.')
          Shift.getGmoFrontendPaymentConfig(this.invoiceId, window.location.href)
            .then((gmoConfig) => {
              this.gmoConfig = gmoConfig
              this.$nextTick(() => (this.$refs.gmoForm as GmoForm).submit())
            })
        } catch (error) {
          this.getGmoFormConfig()
          this.currentStatus = 'payment.error.windowOpenFailed'
          this.retry = this.startSavedCardDetailsAttempt
        }
      }
    } else {
      this.$nextTick(() => (this.$refs.gmoForm as GmoForm).submit())
    }
  }

  private getInputElement(document: HTMLDocument, id: string): HTMLInputElement {
    return document.getElementById(id) as HTMLInputElement
  }

  private handleResult(result: any, code: any = undefined) {
    switch (result) {
      case 'OK': {
        if (this.type === 'PAYMENT') {
          this.$store.dispatch(ACTION_SET_SALE_JOURNEY_GMO_OK, true)
          this.currentStatus = 'payment.info.initiatePayment'
          this.attemptInvoiceSettlement()
        } else {
          this.currentStatus = 'payment.info.detailsUpdateSuccessful'
        }
        break
      }
      case 'ERROR': {
        this.getGmoFormConfig()
        if (['42G120000', '42G300000', '42G970000', '42G550000', 'E41170099'].includes(code)) {
          this.currentStatusCode = code
          this.currentStatus = 'payment.error.payment_failed_' + code
        } else {
          this.currentStatus = 'payment.error.storeMemberIdFailed'
        }
        this.retry = this.startSavedCardDetailsAttempt
        break
      }
      case 'CANCEL': {
        this.getGmoFormConfig()
        this.currentStatus = 'payment.error.windowClosedEarly'
        this.retry = this.startSavedCardDetailsAttempt
        break
      }
      default: {
        this.getGmoFormConfig()
        this.currentStatus = 'payment.error.unknownResult'
        this.retry = this.startSavedCardDetailsAttempt
      }
    }
  }

  private attemptInvoiceSettlement(type = 'GMO') {
    EventBus.emit(POLLING_NAMES.START)
    this.retry = null
    Shift.initSettlement(this.invoiceId, type)
      .then(() => {
        setTimeout(() => {
          this.checkPayment(60000)
        }, 500)
      })
  }

  private cleanUpStateAfterSettlement(callbackFn: Function) {
    // Ensuring duplicate purchases can't be made.
    this.$store.dispatch(ACTION_PRODUCT_EMPTY_CART)
    this.$store.dispatch(ACTION_SET_PRODUCT_LOADED, false)
    this.$store.dispatch(ACTION_RESET_PRODUCT_SERIALIZED_CONFIG)
    this.$store.dispatch(ACTION_SET_BUNDLE, null)
    this.$store.dispatch(ACTION_SET_CONVERT_QUOTE, null)
    this.$store.dispatch(ACTION_SET_SALE_JOURNEY_STATE_PAYMENT_COMPLETED, true)
      .then(() => {
        callbackFn()
      })
  }

  private checkPayment(remainingTime: number) {
    Shift.getSettlementStatus(this.invoiceId)
      .then((settlement) => {
        switch (settlement.status) {
          case 'NOT_STARTED':
          case 'IN_PROGRESS': {
            if (remainingTime <= 0) {
              EventBus.emit(POLLING_NAMES.END)
              this.currentStatus = 'payment.error.paymentTimeout'
              this.retry = this.attemptInvoiceSettlement
            }
            setTimeout(() => {
              this.checkPayment(remainingTime - 500)
            }, 500)
            break
          }
          case 'FAILED': {
            EventBus.emit(POLLING_NAMES.END)
            this.getGmoFormConfig()
            this.currentStatus = 'payment.error.paymentFailed'
            this.retry = this.startSavedCardDetailsAttempt
            break
          }
          case 'SETTLED': {
            EventBus.emit(POLLING_NAMES.END)
            this.cleanUpStateAfterSettlement(() => {
              this.$router.push({
                name: routeNames.APPLICATION_COMPLETED,
                params: { id: this.convertedPolicy.id }
              })
            })
            break
          }
          default: {
            EventBus.emit(POLLING_NAMES.END)
            this.getGmoFormConfig()
            this.currentStatus = 'payment.error.unknownResult'
            this.retry = this.startSavedCardDetailsAttempt
          }
        }
      })
  }

  private doRetry() {
    if (this.retry !== null) {
      this.retry()
    }
  }
}
